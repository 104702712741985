<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>Lego 尋找條件</h5>
                <Dropdown
                    v-model="selectThemeValue"
                    :options="selectThemeValues"
                    :filter="true"
                    :showClear="true"
                    optionLabel="name"
                    placeholder="主題"
                    @change="onThemeChange($event)"
                />
                <Dropdown
                    v-model="selectYearValue"
                    :options="selectYearValues"
                    :filter="true"
                    :showClear="true"
                    optionLabel="year"
                    placeholder="年份"
                    @change="onYearChange($event)"
                />
            </div>
        </div>
        <div class="p-col-12">
            <div class="card">
                <h5>Lego 盒組列表</h5>
                <DataView
                    :value="dataviewValue"
                    :layout="layout"
                    paginatorPosition="both"
                    :paginator="true"
                    :rows="rows"
                    :sortOrder="sortOrder"
                    :sortField="sortField"
                    :totalRecords="totalRecords"
                    :first="firstRow"
                    :lazy="true"
                    @page="onPage($event)"
                >
                    <template #header>
                        <div class="p-grid p-nogutter">
                            <div class="p-col-6" style="text-align: left">
                                <Dropdown
                                    v-model="sortKey"
                                    :options="sortOptions"
                                    optionLabel="label"
                                    placeholder="排序"
                                    @change="onSortChange($event)"
                                />
                            </div>
                            <div class="p-col-6" style="text-align: right">
                                <DataViewLayoutOptions v-model="layout" />
                            </div>
                        </div>
                    </template>

                    <template #list="slotProps">
                        <div class="p-col-12">
                            <div class="product-list-item">
                                <img
                                    :src="slotProps.data.cover_photo"
                                    :alt="slotProps.data.name"
                                />
                                <div class="product-list-detail">
                                    <div class="product-name">
                                        <span
                                            :class="'product-badge status-instock'"
                                            >{{ slotProps.data.set_num }}</span
                                        >
                                        {{ slotProps.data.name }}
                                    </div>
                                    <div class="product-description">
                                        {{ slotProps.data.year }} /
                                        {{
                                            slotProps.data.num_parts.toLocaleString()
                                        }}
                                        parts
                                    </div>
                                    <i
                                        class="pi pi-tag product-category-icon"
                                    ></i
                                    ><span class="product-category">
                                        <span
                                            v-for="item in slotProps.data
                                                .theme_breadcrumb"
                                            :key="item.name"
                                        >
                                            / {{ item.name }}
                                        </span>
                                    </span>
                                </div>
                                <!-- <div class="product-list-action">
                                    <span class="product-price">${{slotProps.data.price}}</span>
                                    <Button icon="pi pi-shopping-cart" label="Add to Cart" :disabled="slotProps.data.inventoryStatus === 'OUTOFSTOCK'"></Button>
                                    <span :class="'product-badge status-'+slotProps.data.inventoryStatus">{{slotProps.data.inventoryStatus}}</span>
                                </div> -->
                            </div>
                        </div>
                    </template>

                    <template #grid="slotProps">
                        <div class="p-col-12 p-md-4">
                            <div class="product-grid-item card">
                                <div class="product-grid-item-top">
                                    <div>
                                        <i
                                            class="
                                                pi pi-tag
                                                product-category-icon
                                            "
                                        ></i>
                                        <span class="product-category">{{
                                            slotProps.data.theme_breadcrumb[0]
                                                .name
                                        }}</span>
                                    </div>
                                    <span
                                        :class="'product-badge status-instock'"
                                        >{{ slotProps.data.set_num }}</span
                                    >
                                </div>
                                <div class="product-grid-item-content">
                                    <img
                                        :src="slotProps.data.cover_photo"
                                        :alt="slotProps.data.name"
                                    />
                                    <div class="product-name">
                                        {{ slotProps.data.name }}
                                    </div>
                                    <div class="product-description">
                                        {{ slotProps.data.year }} /
                                        {{
                                            slotProps.data.num_parts.toLocaleString()
                                        }}
                                        parts
                                    </div>
                                </div>
                                <!-- <div class="product-grid-item-bottom">
                                    <span class="product-price">${{slotProps.data.price}}</span>
                                    <Button icon="pi pi-shopping-cart" :disabled="slotProps.data.inventoryStatus === 'OUTOFSTOCK'"></Button>
                                </div> -->
                            </div>
                        </div>
                    </template>
                </DataView>
            </div>
        </div>
    </div>
</template>

<script>
import SetService from "../service/SetService";

export default {
    data() {
        return {
            dataviewValue: null,
            layout: "grid",
            sortKey: null,
            sortOrder: null,
            sortField: null,
            rows: 21,
            totalRecords: 0,
            firstRow: 0,
            selectThemeValue: null,
            selectThemeValues: [],
            selectYearValue: null,
            selectYearValues: [],
            selectYearAllValues: [],
            currentTheme: this.$route.params.theme
                ? this.$route.params.theme
                : "All",
            currentYear: this.$route.params.year
                ? this.$route.params.year
                : "All",
            currentPage: this.$route.params.page ? this.$route.params.page : 1,
            currentSortBy: "updated_at",
            currentSortOrder: "desc",
            sortOptions: [
                { label: "代號 ⬇️", value: { by: "set_num", order: "desc" } },
                { label: "代號 ⬆️", value: { by: "set_num", order: "asc" } },
                { label: "名字 ⬇️", value: { by: "name", order: "desc" } },
                { label: "名字 ⬆️", value: { by: "name", order: "asc" } },
                {
                    label: "零件數量 ⬇️",
                    value: { by: "num_parts", order: "desc" },
                },
                {
                    label: "零件數量 ⬆️",
                    value: { by: "num_parts", order: "asc" },
                },
                {
                    label: "發行年份 ⬇️",
                    value: { by: "year", order: "desc" },
                },
                { label: "發行年份 ⬆️", value: { by: "year", order: "asc" } },
            ],
        };
    },
    SetService: null,
    created() {
        this.SetService = new SetService();
    },
    mounted() {
        this.SetService.getSearchParams().then((data) => {
            this.selectThemeValues = data.data.themes;
            this.selectYearValues = data.data.years;
            this.selectYearAllValues = data.data.years;

            let vm = this;
            this.selectThemeValues.forEach(function (theme) {
                if (theme.code == vm.currentTheme) {
                    vm.selectThemeValue = theme;
                }
            });
            this.selectYearValues.forEach(function (year) {
                if (year.year == vm.currentYear) {
                    vm.selectYearValue = year;
                }
            });
        });

        this.querySets();
    },
    beforeUpdate() {
        if (
            this.$route.params.theme == "" ||
            this.$route.params.year == "" ||
            this.$route.params.page == ""
        ) {
            this.currentTheme = "All";
            this.currentYear = "All";
            this.currentPage = "1";
            this.selectThemeValue = [];
            this.selectYearValue = [];
            this.querySets();
        }
    },
    methods: {
        onPage(event) {
            this.currentPage = event.page + 1;
            this.querySets();
        },
        onSortChange(event) {
            this.currentSortBy = event.value.value.by;
            this.currentSortOrder = event.value.value.order;
            this.querySets();
        },
        onThemeChange(event) {
            if (event.value == null) {
                this.currentTheme = "All";
                this.selectYearValues = this.selectYearAllValues;
            } else {
                this.currentTheme = event.value.code;
                this.selectYearValues = event.value.years;
            }
            this.currentPage = 1;
            this.querySets();
        },
        onYearChange(event) {
            if (event.value == null) {
                this.currentYear = "All";
            } else {
                this.currentYear = event.value.year;
            }
            this.currentPage = 1;
            this.querySets();
        },
        querySets() {
            this.$router.push({
                path:
                    "/sets/" +
                    this.currentTheme +
                    "/" +
                    this.currentYear +
                    "/" +
                    this.currentPage,
            });

            let params = {
                start: (this.currentPage - 1) * this.rows,
                length: this.rows,
                theme: this.currentTheme,
                year: this.currentYear,
                isFromDataTable: true,
                sortBy: this.currentSortBy,
                sortOrder: this.currentSortOrder,
            };
            this.firstRow = params.start; // 告訴DataView現在是第幾筆開始(計算顯示的頁數)

            this.SetService.getSets(params).then((data) => {
                this.dataviewValue = data.data;
                this.totalRecords = data.recordsFiltered;
            });
        },
    },
};
</script>

<style scoped lang="scss">
.product-name {
    font-size: 1.5rem;
    font-weight: 700;
}

.product-description {
    margin: 0 0 1rem 0;
}

.product-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
}

.product-category {
    font-weight: 600;
    vertical-align: middle;
}

::v-deep(.product-list-item) {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;

    img {
        width: 150px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        margin-right: 2rem;
    }

    .product-list-detail {
        flex: 1 1 0;
    }

    .p-rating {
        margin: 0 0 0.5rem 0;
    }

    .product-price {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        align-self: flex-end;
    }

    .product-list-action {
        display: flex;
        flex-direction: column;
    }

    .p-button {
        margin-bottom: 0.5rem;
    }
}

.product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-outofstock {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-lowstock {
        background: #feedaf;
        color: #8a5340;
    }
}

::v-deep(.product-grid-item) {
    margin: 0.5em;
    border: 1px solid #dee2e6;

    .product-grid-item-top,
    .product-grid-item-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    img {
        width: 75%;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        margin: 2rem 0;
    }

    .product-grid-item-content {
        text-align: center;
    }

    .product-price {
        font-size: 1.5rem;
        font-weight: 600;
    }
}

@media screen and (max-width: 576px) {
    .product-list-item {
        flex-direction: column;
        align-items: center;

        img {
            width: 75%;
            margin: 2rem 0;
        }

        .product-list-detail {
            text-align: center;
        }

        .product-price {
            align-self: center;
        }

        .product-list-action {
            display: flex;
            flex-direction: column;
        }

        .product-list-action {
            margin-top: 2rem;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }
}
::v-deep(.p-multiselect) {
    min-width: 15rem;
}
.multiselect-custom-virtual-scroll .p-multiselect {
    min-width: 20rem;
}
::v-deep(.multiselect-custom .p-multiselect-label) {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.multiselect-custom .country-item.country-item-value {
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: 0.5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
}
.multiselect-custom .country-item.country-item-value span.flag {
    width: 17px;
}
.country-item {
    display: flex;
    align-items: center;
}
.country-item span.flag {
    width: 18px;
    height: 12px;
    margin-right: 0.5rem;
}
.multiselect-custom .country-placeholder {
    padding: 0.25rem;
}
</style>
